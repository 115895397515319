import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import logo from '../../img/logo.png';
import facebook from '../../img/social/facebook.svg';
import instagram from '../../img/social/instagram.svg';
import twitter from '../../img/social/twitter.svg';
import linkedin from '../../img/social/linkedin.svg';
import partner1 from '../../img/partners/logo_bienvenue_a_la_ferme.png';
import partner2 from '../../img/partners/label_vignoblesdecouvertes.png';
import partner3 from '../../img/partners/logo_vignerons_independants.png';
import partner4 from '../../img/partners/Logo-Gaillac.png';
import ab from '../../img/logoab_eurofeuille_biologique.jpg';
import vo from '../../img/vignobles-occitanie.jpg';

import styles from './styles.module.scss';

const Footer = class extends React.Component {
  render() {
    return (
      <>
        <div className={styles.newsletter}>
          <a
            className={styles.newsletter__button}
            href="https://my.sendinblue.com/users/subscribe/js_id/2ruqg/id/1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inscription à la Newsletter
          </a>
        </div>
        <footer className={styles.footer}>
          <div className={styles.footer__col}>
            <Link to="/">
              <img
                className={styles.footer__logo}
                src={logo}
                alt="Domaine Gayrard"
              />
            </Link>
          </div>
          <div
            className={classnames(styles.footer__col, styles.footer__colMain)}
          >
            <section>
              <ul>
                <li className={styles.footer__link}>
                  <Link className="navbar-item" to="/references">
                    Références
                  </Link>
                </li>
                <li className={styles.footer__link}>
                  <Link className="navbar-item" to="/entreprise">
                    Entreprise
                  </Link>
                </li>
                <li className={styles.footer__link}>
                  <Link className="navbar-item" to="/mentions-légales">
                    Mentions légales
                  </Link>
                </li>
              </ul>
            </section>
            <section>
              <ul className="menu-list">
                <li className={styles.footer__link}>
                  <a
                    className="navbar-item"
                    href="https://domaine-gayrard.plugwine.com/Vins"
                  >
                    Boutique
                  </a>
                </li>
                <li className={styles.footer__link}>
                  <Link className="navbar-item" to="/actu">
                    Actualité
                  </Link>
                </li>
                <li className={styles.footer__link}>
                  <Link className="navbar-item" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </section>
            <section className={styles.footer__partners}>
              <img src={ab} alt="Agriculture Biologique" />
              <img src={partner4} alt="Vins de Gaillac" />
              <a href="https://www.vigneron-independant.com/qui-sommes-nous-0">
                <img src={partner3} alt="Logo vignerons indépendants" />
              </a>
              <img src={partner1} alt="Logo Bienvenue à la ferme" />
              <img src={partner2} alt="Logo Vignobles &amp; découvertes" />
              <img
                src={vo}
                alt="Le vin c'est mieux en VO #VignoblesOccitanie"
              />
            </section>
          </div>
          <div
            className={classnames(styles.footer__col, styles.footer__colSocial)}
          >
            <a
              className={styles.footer__social}
              title="facebook"
              href="https://www.facebook.com/maisongayrard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              className={styles.footer__social}
              title="twitter"
              href="https://twitter.com/DomaineGayrard"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="fas fa-lg" src={twitter} alt="Twitter" />
            </a>
            <a
              className={styles.footer__social}
              title="instagram"
              href="https://www.instagram.com/domaine_gayrard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              className={styles.footer__social}
              title="linkedin"
              href="https://www.linkedin.com/in/domaine-gayrard-397761171/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" />
            </a>
          </div>
        </footer>
      </>
    );
  }
};

export default Footer;
