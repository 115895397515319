import React, { useEffect, useState } from 'react'
import classnames from 'classnames';

import styles from './styles.module.scss';
import { useCallback } from 'react';

const MajorityModal = ({ children }) => {
  const [isActive, setActive] = useState();
  const [isVisible, setVisible] = useState();
  const [link, setLink] = useState();

  const toggleModal = useCallback((state = !isActive) => {
    if (state) {
      setActive(state);
      setTimeout(() => setVisible(state), 100);
    } else {
      setVisible(state);
      setTimeout(() => setActive(state), 200);
    }
  }, [isActive])

  const accept = () => {
    const { href, target } = link;
    toggleModal(false);
    localStorage.setItem('isMajor', true);

    if (target === '_blank') {
      global.open(href);
    } else {
      global.location = href;
    }
  }

  useEffect(() => {
    const listener = e => {
      const { target } = e;
      const alreadyAccepted = localStorage.getItem('isMajor')
      const link = target.closest('a');

      if (!link) return;
      
      const href = link.getAttribute('href');

      if (!href.match('domaine-gayrard.plugwine.com')) return;

      e.preventDefault();

      if (alreadyAccepted) {
        window.open(link);
        return;
      };

      setLink({ href, target: link.getAttribute('target') });
      
      toggleModal(true);
    };
    document.body.addEventListener('click', listener)

    return () => document.body.removeEventListener('click', listener);
  }, [toggleModal]);

  return (
    <>
      {children}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      <div
        className={classnames(styles.modal, {
          [styles.modalActive]: isActive,
          [styles.modalVisible]: isVisible
        })}
        onClick={() => toggleModal(false)}
      >
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        <div
          className={styles.modal__content}
          onClick={e => e.stopPropagation()}
        >
          <p>
            Vous vous dirigez vers un site commercialisant des produits interdits aux moins de 18 ans.
          </p>
          <button
            className="button"
            onClick={accept}
          >
            J'ai plus de 18 ans
          </button>
        </div>
      </div>
    </>
  );
}

export default MajorityModal;
