import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';

import logo from '../../img/logo.png';
import styles from './styles.module.scss';
import facebook from '../../img/social/facebook.svg';
import instagram from '../../img/social/instagram.svg';
import linkedin from '../../img/social/linkedin.svg';

const Navbar = class extends React.Component {
  state = {
    active: false,
    navBarActiveClass: '',
  };

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  render() {
    const { links } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.topbar}>
          <div>
            <div className={styles.topbar__space}>
              <a href="tel:+33698175835">06 98 17 58 35</a>
            </div>
            <div className={styles.topbar__space}>
              <Link to="/contact">Contact</Link>
            </div>
          </div>
          <div>Ouvert du lundi au samedi</div>
          <div>
            <a
              href="https://www.facebook.com/maisongayrard"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/domaine_gayrard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/domaine-gayrard/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <nav
          className={styles.navbar}
          role="navigation"
          aria-label="main-navigation"
        >
          <div className={styles.navbar__container}>
            <div className={styles.navbar__logo}>
              <Link to="/" className={styles.navbar__item} title="Logo">
                <img
                  src={logo}
                  style={{ maxWidth: '200px' }}
                  alt="Domaine Gayrard"
                />
              </Link>
              {/* Hamburger menu */}
              {/* eslint-disable jsx-a11y/click-events-have-key-events */}
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              <div
                className={classnames(styles.navbar__burger, {
                  [styles.navbar__burgerActive]: this.state.navBarActiveClass,
                })}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={classnames(styles.navbar__menu, {
                [styles.navbar__menuActive]: this.state.navBarActiveClass,
              })}
            >
              {links.map(({ label, url }) => {
                const key = `${label}${url}`;
                if (url.match(/^http/)) {
                  return (
                    <a key={key} className={styles.navbar__item} href={url}>
                      {label}
                    </a>
                  );
                }
                return (
                  <Link
                    key={key}
                    className={styles.navbar__item}
                    to={url}
                    activeClassName={styles.navbar__itemActive}
                    partiallyActive
                  >
                    <div className={styles.navbar__itemtext}>{label}</div>
                  </Link>
                );
              })}
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query MainNav {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            mainNav {
              label
              url
            }
          }
        }
      }
    `}
    render={({
      markdownRemark: {
        frontmatter: { mainNav },
      },
    }) => <Navbar links={mainNav} />}
  />
);
