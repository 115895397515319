import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../Footer';
import Navbar from '../Navbar';
import useSiteMetadata from '../SiteMetadata';

import styles from './styles.module.scss';

import './legacy.scss';
import MajorityModal from '../MajorityModal';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <html lang="fr" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />

        <link
          href="https://fonts.googleapis.com/css?family=Didact+Gothic"
          rel="stylesheet"
        ></link>
      </Helmet>
      <Navbar />
      <div className={styles.main}>
        <MajorityModal>{children}</MajorityModal>
      </div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
